import React from "react"
import Layout from "../../components/layouts/Layout"
import { graphql, Link } from "gatsby"
import SEO from "../../components/Seo"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const ProductPage = ({ data }) => {
  const schema = {
    "@context": "https://schema.org/",
    "@type": "Product",
    name: data.sanityProduct.name,
    image: "",
    description: data.sanityProduct.description,
    brand: {
      "@type": "Brand",
      name: "Archibald James",
    },
  }

  return (
    <Layout>
      <SEO
        schemaMarkup={schema}
        title={data.sanityProduct.name}
        description={data.sanityProduct.description}
      />
      <div></div>
      <article className="prose prose-lg mx-auto">
        <h1>{data.sanityProduct.name}</h1>
        <div className="mb-8">
          {data.sanityProduct.mainImage && (
            <GatsbyImage
              image={data.sanityProduct.mainImage.asset.gatsbyImageData}
              alt={data.sanityProduct.mainImage.asset.altText}
            />
          )}
        </div>

        <p>{data.sanityProduct.description}</p>
        <p>{data.sanityProduct.pairingSuggestion}</p>

        <h2>Is it available?</h2>
        <p>
          We release products periodically, but supply is limited.{" "}
          <a href="https://club.archibaldjames.com/shop">
            Check availability in our store.
          </a>
        </p>
        <p>
          We produce in small batches. <Link to="/club">Join our club</Link> to
          enjoy our latest creations.
        </p>
      </article>
    </Layout>
  )
}

export default ProductPage

export const query = graphql`
  query ($id: String!) {
    sanityProduct(id: { eq: $id }) {
      id
      name
      description
      pairingSuggestion
      slug {
        current
      }
      mainImage {
        asset {
          gatsbyImageData(fit: FILLMAX, placeholder: BLURRED, width: 500)
          description
          altText
        }
      }
    }
  }
`
